import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import PostPreview from '../components/PostPreview'
import kebabCase from 'lodash/kebabCase'
import styled from 'styled-components'
import inflection from 'inflection'

const AllTagsLink = styled(Link)`
  color: ${props => props.theme.linkColor};
  font-size: 18px;
`

const ItemList = styled.ul`
  margin: 50px 0 0 0;
  padding: 0;
`

const Header = styled.h1`
  margin-bottom: 4px;
`

export default function Tags({ pageContext, data, location }) {
  const siteTitle = data.site.siteMetadata.title
  const { tag, collection, entity } = pageContext
  const { edges, totalCount } = data.allMdx
  const tagHeader = `${totalCount} ${inflection.inflect(
    entity,
    totalCount
  )} tagged with "${tag}"`

  // TODO: the view all link (prefix?) could be passed when creating the page in gatsby-node.js
  let viewAllUrl = '/tags'
  if (collection !== 'blog') {
    viewAllUrl = `${collection}/tags`
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={`${kebabCase(tag)} articles`}
        keywords={[`blog`, `python`, `javascript`, `react`, `gatsby`]}
      />

      <Header>{tagHeader}</Header>
      <AllTagsLink to={viewAllUrl}>View all tags</AllTagsLink>
      {/* TODO: don't use PostPreview for KBItems */}
      <ItemList>
        {edges.map(({ node }) => {
          return <PostPreview post={node} collection={collection} />
        })}
      </ItemList>
    </Layout>
  )
}

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export const pageQuery = graphql`
  query($tag: String, $collection: String, $currentDate: Date!) {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
          tags: { in: [$tag] }
          published: { eq: true }
          date: { lte: $currentDate }
        }
        fields: { collection: { eq: $collection } }
      }
    ) {
      totalCount
      edges {
        node {
          excerpt(pruneLength: 300)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            tags
            slug
          }
        }
      }
    }
  }
`
